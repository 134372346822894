<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
              style="max-width: 550px;"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
          >
            Bienvenido a Sistema de Archivo Digital
          </b-card-title>
          <b-card-text class="mb-2">
            Ingrese a su cuenta para iniciar.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent
            >
              <!-- email -->
              <b-form-group
                  label="Usuario"
                  label-for="login-username"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Username"
                    rules="required"
                >
                  <b-form-input
                      id="login-username"
                      v-model="username"
                      :state="errors.length > 0 ? false:null"
                      name="login-username"
                      placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                  <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small></small>
                  </b-link>
                </div>
                <validation-provider
                    #default="{ errors }"
                    name="Contraseña"
                    rules="required"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder=""
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                    id="remember-me"
                    v-model="rememberMe"
                    name="checkbox-1"
                >
                  Recordarme
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="validationForm"
              >
                <span v-if="!loading">Ingresar</span>
                <span v-if="loading">Cargando...</span>
              </b-button>
            </b-form>
          </validation-observer>


          <b-row>
            <b-col><small>v1.1.2</small></b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-alert
                  variant="danger"
                  show
              >
                <div
                    v-if="errorMessage"
                    class="alert-body font-small-2"
                >
                  <p>
                    <small class="mr-50">
                      <span class="font-weight-bold">{{ errorMessage }}</span>
                    </small>
                  </p>
                </div>
              </b-alert>
            </b-col>
          </b-row>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BAlert,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import {required} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { abilities } from '@/libs/acl/config'
export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      setRememberUser: null,
      status: '',
      rememberMe: false,
      password: '',
      username: '',
      errorMessage: '',
      loading: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
    }
  },
  created() {
    this.getRememberUser()
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async validationForm() {
      this.errorMessage = ''
      this.loading = true
      try {
        const success = await this.$refs.loginValidation.validate()
        if (success) {
          const {data} = await useJwt.login({username: this.username, password: this.password})
          const {token} = data
          const userData = {...data, ability: abilities[data.role]}
          // const userData = {...data, ability: abilities['archive-creator']}
          delete userData.token
          useJwt.setToken(token)
          localStorage.setItem('userData', JSON.stringify(userData))
          localStorage.setItem('rememberMe', this.rememberMe)

          this.$ability.update(userData.ability)

          this.$router.replace('/')
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Bienvenido: ${userData.fullName || userData.username}`,
                    icon: 'CoffeeIcon',
                    variant: 'Info',
                    text: `Has ingresado exitosamente como ${userData.role}.`,
                  },
                })
              })
        }
      } catch (err) {
        console.error(err)
        this.errorMessage = 'Valida los datos ingresados!!'
      } finally {
        this.loading = false
      }
    },
    getRememberUser(){
      if (localStorage.getItem('userData') && localStorage.getItem('rememberMe') === 'true') {
        this.username = JSON.parse(localStorage.getItem('userData')).username
        this.rememberMe = true
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
